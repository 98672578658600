import React from 'react'
import { Image, ScrollView, Text, View, useSx } from 'dripsy'
import { Platform, ImageBackground } from 'react-native'
import { useDim } from 'app/redux/hooks/useDim'
import { connect, useSelector } from 'react-redux'
import * as userAction from '../../redux/actions/user.action'
import * as tokenAction from '../../redux/actions/token.action'
import * as toastAction from '../../redux/actions/toast.action'
import { KeyboardAwareScrollView } from 'app/components/keyboard-aware-scroll-view'
export const AccountLayoutScreen = ({
  children,
  fieldState,
  ...props
}: {
  children?: any
  fieldState?: string
}) => {
  const sx = useSx()
  const { height } = useDim()
  const { clientConfig } = useSelector((state: any) => ({
    clientConfig: state.clientConfig,
  }))

  return (
    <KeyboardAwareScrollView
      style={{
        flex: 1,
        width: '100%'
      }}
    >
      <ImageBackground
        source={require('../../assets/logoin-bg.png')}
        style={sx({
          flex: 1,
          justifyContent: 'center',
          height: height,
          backgroundColor: ['#fff', 'none'],
        })}
        resizeMode={'stretch'}
        imageStyle={{
          height: 150,
          width: '100%',
          position: 'absolute',
          opacity: 0.8,
          top: height - 130,
          ...Platform.select({
            web:{
              top: height - 150,
            }
          })
        }}
      >
        <ScrollView
          contentContainerStyle={Platform.select({
            web: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              height: '100%',
              position: 'relative',
              minHeight: height,
              // backgroundColor:'red'
            },
          })}
        >
          <View
            style={sx({
              minWidth: 320,
              maxWidth: 450,
              minHeight: [height, 'auto'],
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
            })}
          >
            <View
              style={{
                marginBottom: 10,
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                source={require('../../assets/logo-bg.png')}
                alt="logo"
                style={sx({ width: [100], height: [100], marginBottom: 4 })}
              />
              <Text>{clientConfig.name}</Text>
            </View>
            {children}
          </View>
        </ScrollView>
      </ImageBackground>
    </KeyboardAwareScrollView>
  )
}

const mapStateToProps = ({ user, clientConfig }: any) => ({
  user,
  clientConfig,
})

export const AccountLayout = connect(mapStateToProps, {
  ...userAction,
  ...tokenAction,
  ...toastAction,
})(AccountLayoutScreen)
