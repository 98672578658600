
export const FIREBASE_LOGIN_EVENT='login';
export const FIREBASE_SIGN_UP_EVENT='sign_up';
export const FIREBASE_LOGOUT_EVENT='logout';
export const FIREBASE_CREATE_PROJECT_EVENT='create_project';
export const FIREBASE_QUICK_TRY_EVENT='quick_try';
export const FIREBASE_APPLY_FILTER_EVENT='apply_filter';

export const FIREBASE_FORGOT_PASSWORD_EVENT='forgot_password';
export const FIREBASE_CHANGE_PASSWORD_EVENT='change_password';

export const FIREBASE_CANCEL_FILTER_EVENT='cancel_filter';
export const FIREBASE_SAVE_FILTER_EVENT='save_filter';

export const FIREBASE_USER_FEEDBACK_EVENT='user_feedback';

export const FIREBASE_USER_SUPPORT_EVENT='user_support';


