import { useSx, View, Text } from 'dripsy'
import React, { ReactNode, useEffect, useState } from 'react'
import { Platform, TextInputProps, TextInput, ViewStyle } from 'react-native'
import { P } from '../typography/p'
import { Button } from '../button'
import { Icon } from '../icon/icon'

interface TextFieldProps extends TextInputProps {
  showSearchIcon?: boolean
  showRightArrowIcon?: boolean
  helperText?: string
  labelStyle?: ViewStyle
  label?: string| ReactNode
  innerInputLabel?: boolean
  error?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  editable?: boolean
  searchIconStyle?: ViewStyle
  inputStyle?: any
  autoFocus?: boolean
  disabled?: boolean;
  textFieldContainerStyle?:ViewStyle;
  type?: 'password' | 'text' 
}

export function TextField({
  type = 'text', // Default input type to text
  style,
  showSearchIcon = false,
  showRightArrowIcon = false,
  helperText,
  labelStyle,
  label,
  innerInputLabel = false,
  error,
  selectionColor,
  placeholderTextColor,
  underlineColorAndroid,
  startIcon,
  endIcon,
  editable = true,
  searchIconStyle,
  inputStyle = {},
  autoFocus = false,
  textFieldContainerStyle,
  ...props
}: TextFieldProps) {
  const sx = useSx()
  useEffect(() => {
    //
  }, [selectionColor, placeholderTextColor, underlineColorAndroid])
  const inputRef = React.useRef<any>('')
  const [showPassword, setShowPassword] = useState(false) 

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        inputRef?.current && inputRef.current.blur()
        inputRef?.current && inputRef.current.focus()
      }, 100)
    } else {
      inputRef?.current && inputRef.current.blur()
    }
  }, [autoFocus])

  useEffect(() => {
    setShowPassword(type === 'text'?true:false)
  },[type])

  return (
    <View 
      style={style}
    >
      {innerInputLabel ? (
        <P
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            sx({
              padding: 1,
              color: '#0000007E',
              fontSize: 14,
            }),
            labelStyle,
          ]}
        >
          <Text
            style={sx({
              paddingX: 1,
              fontWeight: '500',
            })}
            adjustsFontSizeToFit={true}
            maxFontSizeMultiplier={1.5}
          >
            {label}
          </Text>
        </P>
      ) : null}
      <View
        style={[
          sx({
            alignSelf: 'stretch',
            backgroundColor: !editable ? '$disabled' : '$white',
            borderColor: !editable ? '$disabled' : error ? '$red' : '$primary',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight:30,
            flexDirection:'row',
          }),
          textFieldContainerStyle
        ]}
      >
        {(showSearchIcon || startIcon ? (startIcon ? startIcon : <></>) : <></>) as any}
        <TextInput
          autoFocus={autoFocus}
          editable={editable}
          ref={inputRef}
          style={[
            sx({
              flex: 1,
              color: !editable ? 'grey' : '#000',
              padding: 2,
              height: '100%',
              borderRadius: 5,
              width: '100%',
              paddingX:3,
            }),
            Platform.OS === 'web'
              ? sx({
                  outline: 'none',
                })
              : {},
            inputStyle,
          ]}
          maxFontSizeMultiplier={1.5}
          secureTextEntry={type === 'password' && !showPassword} // Toggle secureTextEntry based on type and showPassword state
          {...props}
        />
          {type === 'password' ?( // Render button only if type is password
          <Button // Button for toggling password visibility
            variantType="text"
            style={sx({ marginRight: 1, borderRadius: 50, width: 50 })}
            onPress={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
                <Icon name="openEye" fill={['#9F9F9F']} />
              ) : (
                <Icon name="closeEye" fill={['#9F9F9F']}  />
              )}
          </Button>
        ):<></>}
        {(showRightArrowIcon || endIcon ? (endIcon ? endIcon : <></>) : <></>) as any}
      </View>
      {helperText ? (
        <P
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            sx({
              padding: 1,
              color: error ? '$red' : '$primary',
            }),
          ]}
          adjustsFontSizeToFit={true}
          maxFontSizeMultiplier={1.5}
        >
          <Text style={sx({})}>{helperText}</Text>
        </P>
      ) : null}
    </View>
  )
}
