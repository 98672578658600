import React from 'react';
import { Platform, ViewStyle } from 'react-native';
import { useRouter } from 'solito/router';
import { Button, ButtonProps } from '../button';
import { Linking } from 'react-native';

interface LinkProps extends ButtonProps {
  children?: any;
  href: string;
  isNextLink?: boolean;
  as?: string;
  target?: string;
  style?: ViewStyle | ViewStyle[];
  nativeBrowser?: boolean
}

export const Link = (props: LinkProps) => {
  const { children, isNextLink = true, nativeBrowser = false, href, as, target = '_self', style, variantType = 'text', ...otherBtnProps } = props;
  const { push } = useRouter();
  return (
    <Button
      variantType={variantType || 'text'}
      style={style}
      onPress={() => {
        if (isNextLink) {
          push(href, as || undefined);
        } else {
          if (typeof window !== 'undefined' && Platform.OS === 'web') window.open(href, target);
          else if (nativeBrowser)
            Linking.openURL(href);
          else push(href, as || undefined);
        }
      }}
      {...otherBtnProps}
    >
      {children}
    </Button>
  );
};
