import React, { ReactNode } from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';

interface SpaceProps extends ViewProps {
  align?: 'start' | 'end' | 'center' | 'baseline';
  direction?: 'vertical' | 'horizontal';
  size?: 'small' | 'middle' | 'large' | number | [number, number] | undefined;
  wrap?: boolean;
  children?: ReactNode;
}

export const Space: React.FC<SpaceProps> = ({
  align = 'start',
  direction = 'horizontal',
  size = 'small',
  wrap = false,
  style,
  children,
  ...otherProps
}) => {
  const flexDirection = direction === 'vertical' ? 'column' : 'row';
  const justifyContent = getJustifyContent(align);

  const containerStyle = StyleSheet.flatten([
    styles.container,
    { flexDirection, justifyContent },
    getSpacingStyles(size),
    wrap && { flexWrap: 'wrap' },
    style
  ]);

  return <View style={containerStyle} {...otherProps}  >{children}</View>;
};

const getJustifyContent = (align: SpaceProps['align']): string => {
  switch (align) {
    case 'start':
      return 'flex-start';
    case 'end':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'baseline':
      return 'baseline';
    default:
      return 'flex-start';
  }
};

const getSpacingStyles = (size: SpaceProps['size']): object => {
  const defaultSpacing = { margin: 4 };
  const spacingMap: any= {
    small: { margin: 4 },
    middle: { margin: 8 },
    large: { margin: 12 },
  };

  if (typeof size === 'number') {
    return { margin: size };
  } else if (Array.isArray(size)) {
    const [horizontalSize = 0, verticalSize = 0] = size;
    return { marginHorizontal: horizontalSize, marginVertical: verticalSize };
  } else if (typeof size === 'string' && spacingMap[size]) {
    return spacingMap[size];
  }

  return defaultSpacing;
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
});
