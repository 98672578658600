import { Action, Dispatch } from 'redux';
import {  LOGIN, LOGOUT, CHECK_USER, REGISTER_USER, SEND_OTP, VERIFY_OTP, RESET_PASSWORD,USER_REFRESH, UPDATE_USER_DATA } from '../constants';

export interface LoginReq {
  email?: string
  password?: string
  strategy?:string
  type?:string
  phone?: string
  username?:string
  firebaseToken?: string
}

interface RegisterReq {
  name: string
  email?: string
  password?: string
  checkPassword?: string
  phone?: string
  otp: string
}

interface OtpReq {
  otp: number,
  phone?: number
  email?:number
}

export const checkUser =
  (user: LoginReq) =>
    (dispatch: Dispatch): Action =>
      dispatch({
        type: CHECK_USER,
        payload: {
          client: 'default',
          request: {
            url: 'api/v1/check-user',
            method: 'POST',
            data: user
          },
        },
      });

    export const login =
    (user: LoginReq) =>{
      return (dispatch: Dispatch): Action =>
      dispatch({
        type: LOGIN,
        payload: {
          client: 'default',
          request: {
            url: 'authentication',
            method: 'POST',
            data: user,
          },
        },
      });
    };

export const logout =
  (navigate:()=>void) =>
    (dispatch: Dispatch): Action =>
      dispatch({
        type: LOGOUT,
        navigate,
        payload: {
          client: 'default',
          request: {
            url: 'api/v1/logout',
            method: 'GET',
          },
        },
      });

export const fetchUserDetails =
  () =>
  (dispatch: Dispatch): Action =>
    dispatch({
      type: USER_REFRESH,
      payload: {
        client: 'default',
        request: {
          url: '/api/v1/me',
          method: 'GET',
        },
    } });

export const register =
  (user: RegisterReq) =>
    (dispatch: Dispatch): Action =>
      dispatch({
        type: REGISTER_USER,
        payload: {
          client: 'default',
          request: {
            url: '/api/v1/users ',
            method: 'POST',
            data: user,
          },
        },
      });

export const sendOtp =
  (user: OtpReq) =>
    (dispatch: Dispatch): Action =>
      dispatch({
        type: SEND_OTP,
        payload: {
          client: 'default',
          request: {
            url: '/api/v1/send-otp ',
            method: 'POST',
            data: user,
          },
        },
      });

export const verifyOtp = (user: OtpReq) =>
  (dispatch: Dispatch): Action =>
    dispatch({
      type: VERIFY_OTP,
      payload: {
        client: 'default',
        request: {
          url: '/api/v1/verify-otp',
          method: 'POST',
          data: user,
        },
      },
    });

export const resetPassword = (user: OtpReq) =>
  (dispatch: Dispatch): Action =>
    dispatch({
      type: RESET_PASSWORD,
      payload: {
        client: 'default',
        request: {
          url: '/api/v1/reset-password',
          method: 'POST',
          data: user,
        },
      },
    });

export const updateUserData = (userObj: any) => {
      return (dispatch: Dispatch): Action =>
        dispatch({
          type: UPDATE_USER_DATA,
          payload: userObj,
        });
    };
